import axios from "axios";

let affiliateSvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;
let crmSvc = process.env.VUE_APP_CRM_BASE;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const affiliateAxiosInstance = axios.create({
	baseURL: affiliateSvc,
});

const crmAxiosInstance = axios.create({
	baseURL: crmSvc,
});

export default {
	affiliateService: {
		searchTransactionsForAccount(companyId, transactionFilter, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				companyId +
				"/affiliate_transactions/search" +
				transactionFilter,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getTransactionFile(searchParams, affiliateID, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/affiliates_file" +
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getHistoryReimbursements(affiliateID, formData, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/reimbursements/search" +
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getDetailsReimbursement(affiliateID, reimbursementID, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/reimbursements/" +
				reimbursementID,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sendReimbursement(affiliateID, formData, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateID + "/reimbursements",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sendStickers(affiliateID, formData, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateID + "/stickers",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getTransactionVolume(transData, token) {
			return affiliateAxiosInstance.get(
				companyRoot + "/v1/customer_transaction_volumes/search" + transData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getDashboardInfos(affiliateID, formData, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/dashboard_infos/search" +
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAllBank(formData, token) {
			return affiliateAxiosInstance.get(
				companyRoot + "/v1/banks/search" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAllTerminals(affiliateID, formData, token) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/terminals/search" +
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getDocumentFile(token, affiliateId, documentType) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateId +
				"/affiliate_docs/" +
				documentType,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getNotifLink(affiliateId, notif, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateId + "/notif",
				notif,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAddtionalActLink(affiliateId, notif, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateId + "/additional_act",
				notif,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCanSignAddtionalAct(affiliateId, token, fiscalCode) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateId +
				"/additional_act_available/" +
				fiscalCode.replace("RO", ""),
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		addNewTerminal(affiliateID, formData, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateID + "/mid_bid",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		modifyTerminal(affiliateID, terminal, token) {
			return affiliateAxiosInstance.put(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/terminals/" +
				terminal.id,
				terminal,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteTerminal(affiliateID, terminalID, token) {
			return affiliateAxiosInstance.delete(
				companyRoot +
				"/v1/affiliates/" +
				affiliateID +
				"/terminals/" +
				terminalID,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getSignContractStatus(formData) {
			delete crmAxiosInstance.defaults.headers.common["api_key"];
			return crmAxiosInstance.get(crmSvc + "educational/exists" + formData, {
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
			});
		},
		searchAffiliate(searchParams, token) {
			return affiliateAxiosInstance.get(
				companyRoot + "/v2/affiliates/search" + searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		payInvoices(affiliateID, formData, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/affiliates/" + affiliateID + "/invoice_payment",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getPaymentOrder(token, affiliateId, paymentId) {
			return affiliateAxiosInstance.get(
				companyRoot +
				"/v1/affiliates/" +
				affiliateId +
				"/invoice_payment/" +
				paymentId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getInvoicesArchive(searchParams, affiliateId, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v1/company/" + affiliateId + "/company_invoice_archive",
				searchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getInvoiceAddress(affiliateId, token) {
			return affiliateAxiosInstance.get(
				companyRoot + "/v2/affiliates/" + affiliateId + "/invoice_addresses",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getContactPerson(affiliateId, token) {
			return affiliateAxiosInstance.get(
				companyRoot + "/v2/affiliates/" + affiliateId + "/contacts",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		changeWorkingPoint(affiliateId, formData, token) {
			return affiliateAxiosInstance.post(
				companyRoot + "/v2/affiliates/" + affiliateId + "/work_point",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		uploadOugFile(affiliateId, invoice, token) {
			let config = {};
			config = {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			};
			return affiliateAxiosInstance.post(
				companyRoot + "/v2/affiliates/" + affiliateId + "/invoice_amount_oug/",
				invoice,
				config
			);
		},
	},
};
